import React from "react"
import { Container, Row, Col } from 'react-bootstrap';
import Header from "./header"
import Footer from "./footer"

const PenetrationAndVulnerabilityTesting = () => {
    return (
        <div>
            <Header  pageTitle="Penetration and Vulnerability Testing"/>
            <section className="py-5 px-md-5 mx-xl-5">
                <Container>
                    <h2 className="text-theme">Penetration and Vulnerability Testing</h2>
                    <Row>
                        <Col md="12">
                            <p>It is industry best practice to perform quarterly internal and external vulnerability assessments
                            to assess the organization’s change and conguration management program as well as the technical
                            security controls in place. It is also a best practice to perform an annual external penetration
                                test to assess the strength of the organization’s perimeter defense. ESG provides:</p>
                        </Col>
                        <Col md="12">
                            <ul>
                                <li><i className="fa fa-chevron-right"></i> Comprehensive internal and external penetration tests</li>
                                <li><i className="fa fa-chevron-right"></i> An executive level summary with High impact vulnerabilities for senior leadership.</li>
                                <li><i className="fa fa-chevron-right"></i> A detailed report with recommended fix actions for system administrators and security engineers.</li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </section>
            <Footer />
        </div>
    )
}
export default PenetrationAndVulnerabilityTesting